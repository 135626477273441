import React, {useEffect, useRef} from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import Banner from "../../../components/banner"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Liquidity Enchancement Program"/>
            <Banner title="Liquidity Enchancement Program"/>
            <section className="service_details_area aspectos servicios">
                <div className="container">
                    <div className="section_title black_c">
                        <p className="f_16">This solutions package is aimed at companies that have already entered the
                            capital markets, however, due to the lack of planning for optimum liquidity in its IPO or
                            follow-ons, or the constant deterioration of their fundamentals or IR program, are in need
                            to re-energize the trading of their securities and reposition the latter in the market.
                            IRStrat’s solution focuses on designing effective strategies encompassing trading, issuance,
                            prospecting and expansion of investor base, as well as improving visibility and perception
                            in the markets, to achieve a higher liquidity and valuation. Our package includes:</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="service_left_sidebar">
                                <ul className="nav nav-tabs service_tab mb_50" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active show" id="paquete1-tab" data-toggle="tab"
                                           href="#paquete1" role="tab"
                                           aria-controls="paquete1" aria-selected="true">Package 1</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete2-tab" data-toggle="tab" href="#paquete2"
                                           role="tab"
                                           aria-controls="paquete2" aria-selected="false">Package 2</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete3-tab" data-toggle="tab" href="#paquete3"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Package 3</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="paquete4-tab" data-toggle="tab" href="#paquete4"
                                           role="tab"
                                           aria-controls="paquete3" aria-selected="false">Package 4</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="paquete1" role="tabpanel"
                                     aria-labelledby="paquete1-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#accordion"
                                                               href="#collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Strategic plan
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse1" className="collapse show" aria-expanded="true"
                                                         role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Initial study
                                                                    on trading depth and reach
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Development of
                                                                    a capacity assessment grid
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Enhanced
                                                                    investors base proxy
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Deconcentration
                                                                    plan
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Follow-on proxy
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Strategic core
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Investment
                                                                    thesis development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Competitive
                                                                    advantage analysis and publishing
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Crafting of the
                                                                    history narrative
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Communication
                                                                    style shaping (qualitative and quantitative mix)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>C-Suite communications
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Market
                                                                    communication training for senior management
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Preparation and
                                                                    consultancy on forums and presentations to investors
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Quarterly
                                                                    earnings speechwriting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Speech drafting
                                                                    and consultancy on corporate transactions (M&As,
                                                                    divestitures and other corporate events)

                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#accordion" href="#collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Policies, procedures and
                                                                metrics structuring
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false" role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Disclosure
                                                                    policy development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Core functions
                                                                    mapping
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>IR flowchart
                                                                    creation (functions and methodology)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Definition of
                                                                    Key Performance Indicators (KPIs)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    corporate governance
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete2" role="tabpanel"
                                     aria-labelledby="paquete2-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p2_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p2_accordion"
                                                               href="#p2_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p2_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Market reporting and
                                                                day-to-day communications
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Earnings
                                                                    release drafting and circle-up
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Annual report
                                                                    and regulatory filings drafting and circle-up
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Investors
                                                                    presentations drafting and quarterly updating
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Quarterly
                                                                    updates of investment fact sheet
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    communication and development of annual guidance
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Press releases
                                                                    drafting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Contact point
                                                                    for investors
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    interviews (traditional and social media)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p2_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Communication and strategic
                                                                disclosure
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Wires
                                                                    publishing strategy
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Distribution of
                                                                    announcements on quarterly earnings reports and
                                                                    conference calls
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Outreach to
                                                                    financial and specialized media
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Distribution
                                                                    list management
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Investor profiling</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Execution of
                                                                    perception studies
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Structuring of
                                                                    shareholders proxy
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Preparation of
                                                                    profile briefing books
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p2_accordion" href="#p2_collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Investor prospecting</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p2_collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Buy-side
                                                                    investors targeting (debt and equity)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    road shows and non-deal road shows
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentations
                                                                    to stock promoters
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Distribution
                                                                    lists management (creation and development)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete3" role="tabpanel"
                                     aria-labelledby="paquete3-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p3_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p3_accordion"
                                                               href="#p3_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p3_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Analyst Coverage
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Express plan to
                                                                    raise coverage
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Development of
                                                                    DCFs valuation models
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Sell-side
                                                                    investors targeting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Presentations
                                                                    to analysis departments
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p3_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Consultancy on trading
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    share buyback operations
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    mirror funds operations
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    market maker operations
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    deconcentration operations
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Consultancy on
                                                                    placements of blocks of shares
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Own events</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Planning and
                                                                    logistics for investor days
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Planning and
                                                                    logistics for site visits
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Planning and
                                                                    logistics for press conferences
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p3_accordion" href="#p3_collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Benchmarking</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p3_collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    annual results (industry, market and region)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    quarterly results (industry, market and region)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Comparative
                                                                    market sentiment (positioning and visibility)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="paquete4" role="tabpanel"
                                     aria-labelledby="paquete4-tab">
                                    <div className="service_right_sidebar p-0 mb-5">
                                        <div className="service_content">
                                            <div id="p4_accordion" role="tablist" aria-multiselectable="true"
                                                 className="panel-group faq-accordion service_accordion">
                                                <div className="card active">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a data-toggle="collapse" data-parent="#p4_accordion"
                                                               href="#p4_collapse1"
                                                               className="btn-accordion"
                                                               aria-expanded="true" role="button"
                                                               aria-controls="p4_collapse1">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Strategic agenda
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p4_collapse1" className="collapse show"
                                                         aria-expanded="true" role="tabpanel">
                                                        <div className="card-block panel-body">


                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>National and
                                                                    international financial events (summits,
                                                                    roundtables, forums, and so on)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Participative
                                                                    lobbying (we seek visibility)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p4_accordion" href="#p4_collapse2"
                                                               className="btn-accordion collapsed" aria-expanded="false"
                                                               aria-controls="p4_collapse2">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>Reports and Press Releases
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="p4_collapse2" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Graphic corporate identity
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Drafting of boiler plate and corporative safe harbor statement
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Structuring and graphical development of the earnings release
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Structuring and graphical editing of the annual and sustainability report
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Interactive deployment of the annual and sustainability report in HTML
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p4_accordion" href="#p4_collapse3"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>IR website – From A to Z</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p4_collapse3" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Structuring and graphical development
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Content drafting
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Optimized platform for mobile devices
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Interactive graphics (includes benchmarking with other securities) and ticker
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Interactive tools (valuation multiples, key performance indicators and stock information)
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" role="tab">
                                                        <h4 className="panel-title">
                                                            <a role="button" data-toggle="collapse"
                                                               data-parent="#p4_accordion" href="#p4_collapse4"
                                                               className="btn-accordion collapsed"
                                                               aria-expanded="false">
                                                                <span className="pluse">+</span><span
                                                                className="minus">-</span>IR Edge 3.0 services</a>
                                                        </h4>
                                                    </div>
                                                    <div id="p4_collapse4" className="panel-collapse collapse"
                                                         aria-expanded="false"
                                                         role="tabpanel">
                                                        <div className="panel-body">

                                                            <ul className="list_style">
                                                                <li><i className="fa fa-angle-right"></i>Web Analytics
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Dedicated servers in the cloud
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Translation services (Spanish-English)
                                                                </li>
                                                                <li><i className="fa fa-angle-right"></i>Intelligence interface
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default Contenido
